import Cookies from 'js-cookie'

export const setCookie = ({ key, content }) => {
  const isProd = process.env.NEXT_PUBLIC_BASE_URL === 'https://clikauto.com'

  Cookies.set(key, content, {
    domain: isProd ? '.clikauto.com' : undefined, // Permite compartir entre subdominios
    path: '/',
    secure: true, // Obligatorio para SameSite=None en HTTPS
    sameSite: 'None',
  })
}

export const getCookie = (key) => {
  return Cookies.get(key)
}

export const removeCookie = (key) => {
  const isProd = process.env.NEXT_PUBLIC_BASE_URL === 'https://clikauto.com'
  Cookies.remove(key, {
    domain: isProd ? '.clikauto.com' : undefined, // Permite compartir entre subdominios
    path: '/',
  })
}
