import gsx from 'styles'

const styles = {
  root: {
    zIndex: 2000,
    '&.sticky': {
      zIndex: 300,
    },
  },

  appbar: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    px: 2,
    borderBottom: 1,
    borderColor: 'divider',
    position: 'fixed',
    zIndex: 2000,
    '&.inverted': {
      backgroundColor: 'third.main',
      color: 'secondary.contrastText',
      zIndex: 2000,
      borderColor: 'divider',
    },
    '&.scrolled': {
      // borderBottom: 'none!important',
      borderColor: 'unset!important',
    },
    '&.noSticky': {
      position: 'relative',
    },
  },

  isAppraisalForm: {
    borderBottom: 0,
    backgroundColor: '#ECF0F1',
  },

  vdp: {
    borderBottom: 0,
    backgroundColor: '#ffffff',
  },

  mobileMenu: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    display: { xs: 'flex', sm: 'flex', md: 'none' },
  },

  mobileMenuIcon: {
    '&.inverted': {
      color: 'white',
    },
  },

  whatsappTag: {
    cursor: 'pointer',
    color: '#00BB2D!important',
    padding: '8px!important',
    paddingRight: '0px!important',
    marginRight: 1,
    '& .MuiIconButton-root': {
      cursor: 'pointer',
      width: 'fit-content',
      height: 'fit-content',
      backgroundColor: 'unset',

      '& .MuiSvgIcon-root': {
        fontSize: 32,
      },
    },
    '&.inverted svg': {
      fill: '#fff!important',
    },
  },

  menu: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    display: { xs: 'none', sm: 'none', md: 'flex' },
  },

  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    '&.hideLogo': {
      display: 'none',
    },
  },
  navbar: {
    '&.hideLogo': {
      display: 'none',
    },
  },

  breadcrumbs: {
    position: 'absolute',
    bottom: 0,
    mt: 1,
    '& a': {
      textDecoration: 'none',
    },
    '& li': {
      fontSize: 10,
    },
    '&.inverted': {
      color: 'secondary.contrastText',
      '& li': {
        color: 'white',
      },
    },
  },

  leaveButton: {
    ...gsx.whiteButton,
    ml: 'auto',
    display: { xs: 'none', sm: 'none', md: 'flex' },
  },

  // menuItem: {
  //   fontWeight: 600,
  //   textTransform: 'none',
  //   borderRadius: '8px!important',
  //   mx: 1,
  //   // paddingX: 2,
  //   fontSize: '14px!important',
  //   lineHeight: '18px!important',
  //   backgroundColor: 'transparent!important',
  //   color: 'text.primary',
  //   '&:hover': {
  //     color: 'primary.main',
  //     '&:after': {
  //       WebkitAnimation: 'scaleUp .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
  //       animation: 'scaleUp .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
  //       WebkitTransform: 'none',
  //       transform: 'none',
  //     },
  //   },
  //   '&:after': {
  //     content: '\'\'',
  //     position: 'absolute',
  //     top: '100%',
  //     height: '4px',
  //     left: 0,
  //     width: '100%',
  //     backgroundColor: 'primary.main',
  //     transform: 'scaleX(0)',
  //     transition: 'transform .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
  //     WebkitTransformOrigin: 'center',
  //     transformOrigin: 'center',
  //   },
  //   '&.selected': {
  //     borderRadius: '0px!important',
  //     color: 'primary.main',
  //     '&:after': {
  //       WebkitAnimation: 'scaleUp .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
  //       animation: 'scaleUp .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
  //       WebkitTransform: 'none',
  //       transform: 'none',
  //     },
  //   },
  //   '&.inverted': {
  //     color: 'secondary.contrastText',
  //   },
  //   '&.selected.inverted': {
  //     backgroundColor: 'primary.main',
  //   },
  // },

  menuItem: {
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '8px!important',
    mx: 1,
    fontSize: '14px!important',
    lineHeight: '18px!important',
    backgroundColor: 'transparent!important',
    color: 'text.primary',
    '&.MuiMenuItem-root': {
      fontSize: '14px!important',
      lineHeight: '18px!important',
    },
    '&:hover': {
      color: 'primary.main',
      '&:not(.MuiMenuItem-root)::after': {
        WebkitAnimation: 'scaleUp .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
        animation: 'scaleUp .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
        WebkitTransform: 'none',
        transform: 'none',
      },
    },
    '&:after': {
      content: '\'\'',
      position: 'absolute',
      top: '100%',
      height: '4px',
      left: 0,
      width: '100%',
      backgroundColor: 'primary.main',
      transform: 'scaleX(0)',
      transition: 'transform .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
      WebkitTransformOrigin: 'center',
      transformOrigin: 'center',
    },
    '&.selected': {
      borderRadius: '0px!important',
      color: 'primary.main',
      '&:after': {
        WebkitAnimation: 'scaleUp .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
        animation: 'scaleUp .6s cubic-bezier(0.694, 0.0482, 0.335, 1)',
        WebkitTransform: 'none',
        transform: 'none',
      },
    },
    '&.inverted': {
      color: 'secondary.contrastText',
    },
    '&.selected.inverted': {
      backgroundColor: 'primary.main',
    },
  },

  drawer: {
    width: '100%!important',
    '& .MuiDrawer-paper': {
      width: '100%!important',
    },
  },

  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingX: 2,
  },

  // mobileMenuItem: {
  //   textAlign: 'center',
  //   paddingY: 3,
  //   '& .MuiTypography-root': {
  //     fontWeight: '600!important',
  //     color: 'third.main',
  //     margin: '0 auto',
  //     maxWidth: 150,
  //     lineHeight: '18px!important',
  //     fontSize: '16px!important',
  //   },
  //   '&.selected': {
  //     color: 'primary.main',
  //     '& .MuiTypography-root': {
  //       color: 'primary.main',
  //       fontWeight: '600!important',
  //       paddingBottom: 1,
  //       borderBottom: 2,
  //       borderColor: 'primary.main',
  //     },
  //   },
  //   borderBottom: 'none',
  // },
  mobileMenuItem: {
    textAlign: 'left',
    '& .MuiTypography-root': {
      fontWeight: '600!important',
      color: 'third.main',
      lineHeight: '18px!important',
      fontSize: '16px!important',
    },
    '&.active': {
      color: 'primary.main',
      '& .MuiTypography-root': {
        color: 'primary.main',
        fontWeight: '600!important',
      },
      '& .MuiListItemIcon-root svg > *': {
        fill: (theme) => `${theme.palette.primary.main}`,
      },
    },
    '&.selected': {
      '& .MuiListItemButton-root ': {
        '& .MuiTypography-root': {
          color: 'secondaryVariant.main',
        },
        '& svg *': {
          fill: (theme) => `${theme.palette.secondaryVariant.main}!important`,
        },
      },
    },
    borderBottom: 'none',
  },

  blueIcon: {
    color: 'third.main',
  },

}

export default styles
